import React, { useEffect, useState } from "react";
import { ActionFunctionArgs, useLoaderData, Form } from "react-router-dom";
import { Service } from "../service";
import { LoaderData, SitePayload } from "../../types";
import { LoaderFunctionArgs, redirect } from "react-router";
import {
    AppBar,
    Button,
    Card,
    Checkbox,
    Chip,
    ChipProps,
    FormControlLabel,
    FormGroup,
    Modal,
    TextField,
    InputAdornment,
    IconButton,
    CircularProgress,
    InputLabel,
    FormControl,
    Select,
    MenuItem,
    FormHelperText
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from "@mui/material/Tooltip";
import { authenticatedRequest } from "../authenticate/authenticatedRequest";

const PLATFORM_INGRESS_PATTERN = "cloud2-stg.rezfusion.com";

export interface SiteProps {
    name: string;
    hostnames: string[];
    id: string;
    service: "blue" | "green"
    canonicalHostname: string;
    enableWww: boolean;
    brandSlug: string;
    isProd: boolean;
    theme: string;
}

export async function site({ params }: LoaderFunctionArgs): Promise<SiteProps> {
    if (!params.id) {
        return {
            name: "",
            id: "",
            service: "blue",
            canonicalHostname: "",
            enableWww: false,
            hostnames: [],
            brandSlug: "bluetent",
            isProd: false,
            theme: "astra-child",
        }
    }

    const data = await authenticatedRequest(`${process.env.REACT_APP_PROXY_SERVER}/api/sites/${params.id}`)
        .then(r => r.json());
    return {
        name: data.name || "",
        id: data.id || "",
        service: data.service || "",
        canonicalHostname: `${data.id}.${process.env.REACT_APP_CANONICAL_HOST}`,
        enableWww: data.enableWww,
        hostnames: data.hostnames || [],
        brandSlug: data.brandSlug || "",
        isProd: data.isProd,
        theme: data.theme,
    }
}

const Loader = () => (<CircularProgress color="primary"></CircularProgress>);

export const SiteLoader = (): React.JSX.Element => {
    const props = useLoaderData() as LoaderData<typeof site>;
    return (
        <React.Suspense fallback={<Loader />}>
            <Site name={props.name}
                hostnames={props.hostnames}
                id={props.id}
                service={props.service}
                canonicalHostname={props.canonicalHostname}
                enableWww={props.enableWww}
                brandSlug={props.brandSlug}
                isProd={props.isProd}
                theme={props.theme}
            />
        </React.Suspense>
    )
}

export const SiteConfigPage = (): React.JSX.Element => {
    const props = useLoaderData() as LoaderData<typeof site>;
    return (
        <Card sx={{ mx: "auto", p: 1, maxWidth: "950px", width: "100%" }}>
            <SiteForm isNew={props.id === ""} />
        </Card>
    )
}

export async function createSite({ params, request }: ActionFunctionArgs): Promise<{ ok: boolean, reason?: string }> {
    const form = document.getElementById("site-create-form") as HTMLFormElement;
    const formData = await request.formData();
    if (!form.checkValidity()) {
        return { ok: false, reason: 'bad site ID' };
    }
    // eslint-disable-next-line no-restricted-globals
    const conf = confirm(
        `You are about to create & provision a new website with the following ID: ${formData.get("id")}. 
        Are you sure you want to proceed? This action creates a database, S3 file system, and a repository entry for the entered website.`
    );
    if (!conf) {
        return { ok: false, reason: 'cancelled' }
    }
    const payload: SitePayload = {
        name: formData.get("name"),
        id: formData.get("id"),
        service: formData.get("service"),
        enableWww: formData.get("enableWww") === 'on',
        hostnames: formData.getAll('hostnames'),
        canonicalHostname: formData.get("canonicalhostname"),
        brandSlug: formData.get('brandSlug'),
        isProd: formData.get('isProd'),
        theme: formData.get('theme')
    };
    if (!payload.brandSlug && (!payload.hostnames || payload.hostnames.length <= 0)) {
        alert('At least one valid hostname must be present.');
        return { ok: false, reason: 'Empty hostname value.' }
    }

    if (!payload.canonicalHostname && !payload.brandSlug) {
        alert('Invalid canonical hostname. A hostname must be specified, either manually or via the selected brandSlug.');
        return { ok: false, reason: 'Empty canonical hostname value.' }
    }

    // First, create the repository entry for the new site.
    const repoCreateRes = await authenticatedRequest(
        `${process.env.REACT_APP_PROXY_SERVER}/api/sites/create`,
        {
            method: "post",
            body: JSON.stringify(payload),
        }
    );
    if (!repoCreateRes.ok) throw repoCreateRes;

    // If it succeeded, then move on to provisioning the website. This will initialize the DB and codebase as well as the 
    // administrative and DB-related secrets. Additionally, an S3 bucket keyed by the site ID is created.
    const provisionSiteRes = await authenticatedRequest(
        `${process.env.REACT_APP_PROXY_SERVER}/api/sites/${payload.id}/provision`,
        {
            method: "put",
            body: JSON.stringify({}),
        }
    );
    if (!provisionSiteRes.ok) {
        console.error(provisionSiteRes);
        alert(provisionSiteRes)
        throw provisionSiteRes
    };

    return redirect('/sites');
}

export async function updateSite({ params, request }: ActionFunctionArgs): Promise<{ ok: boolean, reason?: string }> {
    const formData = await request.formData();

    const payload: SitePayload = {
        name: formData.get("name"),
        id: params.id,
        service: formData.get("service"),
        enableWww: formData.get("enableWww") === 'on',
        hostnames: formData.getAll('hostnames'),
        canonicalHostname: formData.get("canonicalhostname"),
        brandSlug: formData.get('brandSlug'),
        isProd: formData.get('isProd'),
        theme: formData.get('theme'),
    };

    if (!payload.canonicalHostname && !payload.brandSlug) {
        alert('Invalid canonical hostname. A hostname must be specified, either manually or via the selected brandSlug.');
        return { ok: false, reason: 'Empty canonical hostname value.' }
    }
    if (!payload.brandSlug && (!payload.hostnames || payload.hostnames.length <= 0)) {
        alert('At least one valid hostname must be present.');
        return { ok: false, reason: 'Empty hostname value.' }
    }

    // First, update the repository entry for this site.
    const repoUpdateRes = await authenticatedRequest(
        `${process.env.REACT_APP_PROXY_SERVER}/api/sites/${params.id}`,
        {
            method: "post",
            body: JSON.stringify(payload),
        }
    );
    if (!repoUpdateRes.ok) throw repoUpdateRes;
    // Then, fire off 
    const siteUpgradeRes = await authenticatedRequest(
        `${process.env.REACT_APP_PROXY_SERVER}/api/sites/${params.id}/promote`,
        {
            method: "put",
            body: JSON.stringify({}),
        }
    );
    if (!siteUpgradeRes.ok) throw siteUpgradeRes;
    return redirect('/sites');
}

type AddHostnameModalProps = {
    setHostnames: (hostnames: string) => void,
    open: boolean,
    handleClose: (open: boolean) => void
};

function isValidDomain(domain: string) {
    const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/;
    return domainRegex.test(domain);
}

export const AddHostnameModal = ({ open, handleClose, setHostnames }: AddHostnameModalProps): React.JSX.Element => {
    const [newHostname, setNewHostname] = useState<string>("");
    const [hostnameValid, setHostnameValid] = useState<boolean>(false);
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
    };
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{ ...style }}>
                <Grid container>
                    <Grid container>
                        <Grid item sm={12}>
                            <AppBar position="relative">
                                <Box display="flex" justifyContent="end" alignContent="center">
                                    <Typography
                                        py={1.5}
                                        px={2}
                                        component="h1"
                                        variant="h6"
                                        color="inherit"
                                        noWrap
                                        sx={{ flexGrow: 1 }}
                                    >
                                        Add Hostname
                                    </Typography>
                                    <Box py={1}
                                        px={2}
                                        pr={2}>
                                        <IconButton
                                            aria-label="exit adding new hostnames"
                                            onClick={() => {
                                                handleClose(false);
                                            }}
                                            edge="end"
                                        >
                                            <CloseIcon sx={{ color: "white" }} />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </AppBar>
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid container p={2}>
                        <Grid item sm={12}>
                            <TextField
                                label="Hostname"
                                value={newHostname}
                                sx={{ width: "100%" }}
                                onChange={(e) => {
                                    const valid = isValidDomain(e.target.value);
                                    setHostnameValid(valid);
                                    setNewHostname(e.target.value)
                                }}
                                error={!hostnameValid && newHostname !== ""}
                                helperText={!hostnameValid && newHostname !== "" ? "Invalid domain name. Do not include http or https." : ""}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="save new hostname"
                                                onClick={() => {
                                                    if (hostnameValid) {
                                                        setHostnames(newHostname);
                                                        setNewHostname("");
                                                    }
                                                }}
                                                color={newHostname !== "" ? "success" : undefined}
                                                disabled={newHostname === "" || !hostnameValid}
                                                edge="end"
                                            >
                                                <Tooltip title="Save new Hostname">
                                                    <AddCircleIcon />
                                                </Tooltip>
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
}

const HostnameChip = (props: ChipProps): React.JSX.Element => {
    return (
        <>
            <Chip {...props} />
            <input name="hostnames" type="hidden" value={`${props.label}`} />
        </>
    )
}

export const getHostnameChips = ({
    id,
    hostnames,
    deleteCallback
}: { hostnames: string[], deleteCallback: (hostname: string) => void, id: string }): React.JSX.Element[] => {
    return hostnames.map(h => h === `${id}.${PLATFORM_INGRESS_PATTERN}`
        ? (<HostnameChip
            sx={{ mr: 1 }}
            key={h}
            label={h}
            variant="outlined" />)
        : (<HostnameChip
            sx={{ mr: 1 }}
            key={h}
            label={h}
            onDelete={() => deleteCallback(h)}
            deleteIcon={<DeleteIcon />}
            variant="outlined" />)
    )
}

export const SiteForm = (props: { isNew: boolean }): React.JSX.Element => {
    const data = useLoaderData() as LoaderData<typeof site>;
    const [id, setSiteId] = useState<string>(data.id);
    const [enableWww, setEnableWww] = useState<boolean>(data.enableWww);
    const [hostnames, setHostnames] = useState<string[]>(data.hostnames);
    const [service, setService] = useState<string>(data.service);
    const [hostnameModalOpen, setHostnameModalOpen] = useState<boolean>(false);
    const [canonicalHostname, setCanonicalHostname] = useState<string>(`${data.id}.${process.env.REACT_APP_CANONICAL_HOST}`);
    const [isProd, setIsProd] = useState<boolean>(data.isProd);
    const [brandSlug, setBrandSlug] = useState<string>(data.brandSlug);
    const [theme, setTheme] = useState<string>(data.theme);
    useEffect(() => {
        setSiteId(`${data.id}`);
    }, [data.id])
    const hostnameChips = [...getHostnameChips({
        hostnames,
        id: id,
        deleteCallback: (hostname: string) => {
            const newHostnames = hostnames.filter(h => h !== hostname);
            setHostnames(newHostnames);
        },
    }), <Chip label="Add Hostname" onClick={() => setHostnameModalOpen(true)} />];
    return (
        <>
            <Form id="site-create-form" method="post">
                <input type="hidden" name="isNew" value={`${props.isNew}`} />
                <Grid container spacing={1}>
                    <Grid p={2} pr={1} container>
                        <Grid pr={2} item sm={6} md={7}>
                            <TextField
                                sx={{ width: "100%" }}
                                label="Site Title/Name"
                                name="name"
                                inputProps={{
                                    required: true,
                                    pattern: `[A-Z|a-z|0-9]{1,64}`
                                }}
                                onChange={(e) => {
                                    setSiteId(`${e.target.value}`);
                                }}
                                defaultValue={data.name || ""}
                                helperText="Site names should short and useful. By default this is the homepage title. The maximum character amount is 64; special characters, numbers, and lower/uppercase letters are allowed."
                            />
                        </Grid>
                        <Grid item sm={6} md={5}>
                            <TextField
                                sx={{ width: "100%" }}
                                name="id"
                                required
                                inputProps={{
                                    required: true,
                                    pattern: `[a-z]{4,6}`
                                }}
                                onChange={(e) => {
                                    setSiteId(`${e.target.value}`);
                                }}
                                disabled={!props.isNew}
                                defaultValue={id || ""}
                                helperText={props.isNew ? "Enter a short (4-6 characters), unique value to use as an ID. No spaces or special characters. This ID determines the database, filesystem sub-directory, and all other site-specific values." : "Site IDs are not editable."}
                            />
                        </Grid>
                    </Grid>
                    <Grid p={2} pr={1} container>
                        <FormControl fullWidth>
                            <InputLabel id="service-select-label">Active Service</InputLabel>
                            <Select
                                name="service"
                                labelId="service-select-label"
                                id="service-selector"
                                defaultValue={service}
                                label="Service"
                                onChange={(e) => setService(e.target.value)}
                            >
                                <MenuItem value={'blue'}>Blue Service</MenuItem>
                                <MenuItem value={'green'}>Green Service</MenuItem>
                            </Select>
                            <FormHelperText>
                                The service determines what underlying code a given site uses to run.
                                New features/features being QA-d for client approval are often on the active canary service. 
                                The latest stable release of code is on the production service. 
                                The value of this can be changed throughout the lifecycle of a website to facilitate easy canary testing/QA & release cycles platform-wide.
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid p={2} pr={1} container>
                        <FormControl fullWidth>
                            <InputLabel id="brandSlug-select-label">Site Brand</InputLabel>
                            <Select
                                name="brandSlug"
                                labelId="brandSlug-select-label"
                                id="brandSlug-selector"
                                defaultValue={brandSlug}
                                label="brandSlug"
                                onChange={(e) => setBrandSlug(e.target.value)}
                            >
                                <MenuItem value={'bluetent'}>Bluetent</MenuItem>
                                <MenuItem value={'q4l'}>Q4Launch</MenuItem>
                                <MenuItem value={'lmpm'}>LMPM</MenuItem>
                            </Select>
                            <FormHelperText>
                                The site brandSlug determines the canonical hostname value. 
                                Bluetent sites, Q4L sites, and LMPM sites all may have different patterns which should not be changed.
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid container p={2} pt={0}>
                        <Grid item sm={12}>
                            <Typography
                                component="h1"
                                variant="h6"
                                color="inherit"
                                noWrap
                                sx={{ flexGrow: 1 }}
                            >
                                Domains and Routing
                            </Typography>
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid container p={2} pr={1}>
                        <Grid item sm={12}>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox name="enableWww" checked={enableWww} onChange={(e) => setEnableWww(e.target.checked)} />}
                                    label="Redirect traffic to WWW"
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <Grid container p={2} pt={0}>
                        <Grid item sm={12} pb={2}>
                            <Typography
                                component="h1"
                                variant="h6"
                                color="inherit"
                                noWrap
                                sx={{ flexGrow: 1 }}
                            >
                                Eligible Hostnames
                            </Typography>
                            <FormHelperText>
                                The eligible hostnames should include the implementation URL & and other hostnames you would like to resolve to this website.
                                If you have configured the brandSlug above then the implementation URL will be set, automatically, upon site creation.
                            </FormHelperText>
                            <Divider />
                        </Grid>
                        <Grid item sm={12}>
                            <Grid item sm={12}>
                                {hostnameChips.map(c => c)}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container p={2} pt={0}>
                        <Button type="submit" color="primary" className="contrast" variant="contained">
                            {props.isNew ? `Create Website` : `Save Site Configuration`}
                        </Button>
                    </Grid>
                </Grid>
            </Form>
            <AddHostnameModal
                open={hostnameModalOpen}
                setHostnames={(newHostname: string) => {
                    hostnames.push(newHostname);
                    setHostnames(hostnames);
                    setHostnameModalOpen(false)
                }}
                handleClose={() => setHostnameModalOpen(false)}
            />
        </>
    )
}

export const Site = (props: SiteProps): React.JSX.Element => {
    return (
        <div className="site">
            <React.Suspense fallback={<Loader />}>
                <h3>
                    {props.name} ({props.id})
                </h3>
                <div>
                    <h4>Hostname</h4>
                    {props.canonicalHostname}
                    <h4>Responds at</h4>
                    <div>
                        {props.hostnames.join(",")}
                    </div>
                </div>
                <div>
                    <h5>Running on Service: <Service service={props.service} /></h5>
                </div>
            </React.Suspense>
        </div>
    )
}